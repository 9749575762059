<template>
  <section id="main-wrapper" style="min-height: 97vh">
    <div class="container">
      <!-- End Navigation -->
      <div class="clearfix"></div>
      <!-- ============================================================== -->
      <!-- Top header  -->
      <!-- ============================================================== -->

      <!-- ============================ Page Title Start================================== -->
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Get in Touch</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Page Title End ================================== -->

      <!-- ============================ Agency List Start ================================== -->
      <section style="padding-top: 40px">
        <!-- class="bg-light" -->
        <div class="container">
          <!-- row Start -->
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="prc-wrap">
                <div class="prc-wrap-header">
                  <h4 class="property-block-title">Reach Us</h4>
                </div>

                <div class="prc-wrap-body">
                  <div class="contact-info">
                    <div class="cn-info-detail">
                      <div class="cn-info-icon">
                        <i class="ti-email"></i>
                      </div>
                      <div class="cn-info-content">
                        <h4 class="cn-info-title">Drop A Mail</h4>
                        suzan@971tutors.com<br />support.971tutors@gmail.com
                      </div>
                    </div>

                    <div class="cn-info-detail">
                      <div class="cn-info-icon">
                        <i class="ti-mobile"></i>
                      </div>
                      <div class="cn-info-content">
                        <h4 class="cn-info-title">Call Us</h4>
                        Founder and CEO Suzan Said Yazbeck<br />
                        +971 50 476 2522
                      </div>
                    </div>

                    <div class="cn-info-detail">
                      <div class="cn-info-icon">
                        <i class="ti-info-alt"></i>
                      </div>
                      <div class="cn-info-content">
                        <h4 class="cn-info-title">Policies</h4>
                        <b-link to="/refundpolicy">Refund Policy</b-link>
                        <br />
                        <b-link to="/privacypolicy">Privacy Policy</b-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
      </section>
      <!-- ============================ Agency List End ================================== -->
    </div>
  </section>
</template>

<script>
export default {
  name: "cmp-contact",
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
};
</script>

<style>
</style>